<script lang="ts" setup>
import type { ParsedContent } from '@nuxt/content'

const { data: navigation } = await useAsyncData('navigation', () => fetchContentNavigation(), {
  default: () => []
})
const { data: files } = useLazyFetch<ParsedContent[]>('/api/content/search.json', {
  default: () => [],
  server: false
})

provide('navigation', navigation)
</script>

<template>
  <div>
    <BrochurewareHeader />

    <UMain>
      <slot />
    </UMain>

    <BrochurewareFooter />

    <ClientOnly>
      <LazyUContentSearch :files="files" :navigation="navigation" />
    </ClientOnly>
  </div>
</template>
