<script setup lang="ts">
import type { NavItem } from '@nuxt/content'

const authStore = useAuthStore()

const navigation = inject<Ref<NavItem[]>>('navigation', ref([]))

const links = [
  {
    label: 'Docs',
    to: '/docs'
  },
  {
    label: 'Pricing',
    to: '/pricing'
  },
  {
    label: 'Blog',
    to: '/blog'
  }
]
</script>

<template>
  <UHeader :links="links">
    <template #logo>Example Company</template>

    <template #right>
      <template v-if="authStore.isLoggedIn">
        <UButton
          label="My account"
          icon="i-heroicons-arrow-right-20-solid"
          trailing
          color="black"
          to="/platform"
          class="hidden lg:flex"
        />
      </template>

      <template v-else>
        <UButton label="Sign in" color="gray" to="/auth/login" />

        <UButton
          label="Sign up"
          icon="i-heroicons-arrow-right-20-solid"
          trailing
          color="black"
          to="/auth/register"
          class="hidden lg:flex"
        />
      </template>
    </template>

    <template #panel>
      <UNavigationTree :links="mapContentNavigation(navigation)" default-open />
    </template>
  </UHeader>
</template>
